body {
  background: rgb(12,12,12);
  background: radial-gradient(circle, rgba(12,12,12,1) 0%, rgba(0,0,10,1) 62%);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
margin:0px;
}

/* Default for mobile (no margin) */
.App {
  margin: 0;
}
.css-5c1adp-MuiContainer-root {
  padding-left:0px !important;
  padding-right:0px !important;
}

/* Small margin for larger screens */
@media (min-width: 600px) {
  .App {
    margin: 10px; /* Small margin for screens 600px and larger */
  }
}

/* Larger margin for even bigger screens */
@media (min-width: 1024px) {
  .App {
    margin-top: 50px; /* Large margin for screens 1024px and larger */
    margin-left: 200px;
    margin-right: 200px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
