/* General styling for the tier list container */
.tier-list {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

/* Base styling for each tier */
.tier {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

/* Header for each tier */
.tier-header {
  width: 50px;
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  color: #000;
  padding: 10px;
  border-radius: 8px;
  margin-right: 10px;
}

/* Tiers specific pastel background colors */
.tier-S .tier-header { background-color: #ffb3b3; } /* Pastel Red */
.tier-A .tier-header { background-color: #ffcc99; } /* Pastel Orange */
.tier-B .tier-header { background-color: #ffff99; } /* Pastel Yellow */
.tier-C .tier-header { background-color: #ccffcc; } /* Pastel Green */
.tier-D .tier-header { background-color: #d9b3ff; } /* Pastel Purple */
.tier-F .tier-header { background-color: #e0e0e0; } /* Pastel Grey */

/* Content styling for the items in each tier */
.tier-content {
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
}

/* Styling for each card */
.tier-card {
  width: 100px;
  margin: 0 10px;
  text-align: center;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
}

.tier-card img {
  width: 100%;
  border-bottom: 1px solid #ddd;
  border-radius: 8px 8px 0 0;
}

.tier-card-name {
  padding: 5px;
  font-size: 0.9rem;
  color: #333;
}
